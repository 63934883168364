import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const LandscapeMediaWithContent = ({ module }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isLightBoxOpen, setLightBoxOpenStatus] = useState(false);

  return (
    <>
      <section className={`content-slider lg:py-70 py-35${module.extraClass ? ` ${module.extraClass}` : ""}`}>
        <div className="container-fluid">
          <div className="flex flex-wrap items-center">
            <div className="lg:w-7/12 fade-img w-full">
              <Swiper
                spaceBetween={30}
                loop={true}
                slidesPerView={1}
                speed={900}
                pagination={true}
                navigation={true}
                modules={[Navigation, Pagination]}
                className="mySwiper"
              >
                {
                  module?.gallery?.length > 0 && module.gallery.map((slide, slideIndex) => (
                    <SwiperSlide 
                      key={slideIndex.toString()} 
                      onClick={() => {
                        setPhotoIndex(slideIndex);
                        setLightBoxOpenStatus(true);
                      }}
                      className="cursor-pointer">
                      <GatsbyImage
                        image={getImage(slide?.image)}
                        alt={slide?.image?.altText}
                        className="img"
                      />
                      {
                        slide?.image?.caption && (
                          <span className="absolute bottom-35 left-40 text-white smscreen2:bottom-20 smscreen2:left-25 z-99">{parse(slide?.image?.caption)}</span>
                        )
                      }
                    </SwiperSlide>
                  ))
                }
              </Swiper>
            </div>
            <div className="lg:w-5/12 fade-ani w-full">
              <div className="content-slider-info max-w-[330px] m-auto lgscreen:max-w-full lgscreen:pt-30">
                {
                  module?.heading && (
                    <div className="title-black">
                      <h3>{module.heading}</h3>
                    </div>
                  )
                }
                {
                  module?.content && (
                    <div className="content">
                      {parse(module.content)}
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      {isLightBoxOpen && (
        <Lightbox
          mainSrc={module?.gallery?.[photoIndex]?.image.mediaItemUrl}
          nextSrc={module?.gallery[(photoIndex + 1) % module?.gallery.length]}
          onImageLoadError={() => { }}
          imageLoadErrorMessage={() => { }}
          prevSrc={
            module?.gallery[
            (photoIndex + module?.gallery.length - 1) % module?.gallery.length
            ]
          }
          onCloseRequest={() => setLightBoxOpenStatus(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex) =>
                (photoIndex + module?.gallery.length - 1) % module?.gallery.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex(
              (photoIndex) => (photoIndex + 1) % module?.gallery.length
            )
          }
        />
      )}
    </>
  )
}

export default LandscapeMediaWithContent;
export const LandscapeMediaWithContentFragment = graphql`
  fragment LandscapeMediaWithContentFragment on WpAccommodation_Accommodations_PageContent_LandscapeMediaWithContent {
    id
    hideSection
    content
    cta {
      target
      title
      url
    }
    extraClass
    gallery {
      image {
        altText
        gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1024)
        caption
        mediaItemUrl
      }
    }
    heading
    makeAnEnquiry {
      target
      title
      url
    }
  }
`;