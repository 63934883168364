import React from "react";
import { graphql } from "gatsby";
import SinglePageBanner from "../components/SinglePageBanner";
import ImageWithContent from "../components/ImageWithContent";
import SimpleContent from "../components/SinglePage/SimpleContent";
import Gallery from "../components/Gallery";
import ImageWithTabContent from "../components/ImageWithTabContent";
import RelatedAccommodations from "../components/RelatedAccommodations";
import LandscapeMediaWithContent from "../components/LandscapeMediaWithContent";

const Accommodation = (props) => {
  const modules = props.modules.pageContent;

  const components = {
    SinglePageBanner,
    ImageWithContent,
    SimpleContent,
    Gallery,
    ImageWithTabContent,
    RelatedAccommodations,
    LandscapeMediaWithContent
  }

  return (
    <>
      {modules
        ? modules.filter((module) => !module?.hideSection || module?.hideSection === "no").map((module, i) => {
            const moduleName = module.__typename.replace(
              "WpAccommodation_Accommodations_PageContent_",
              ""
            );
            return (
              components[moduleName] &&
              React.createElement(components[moduleName], {
                key: i,
                module,
                i,
              })
            );
          })
        : null}
    </>
  )
};

export default Accommodation;

export const AccommodationFragment = graphql`
  fragment AccommodationFragment on WpAccommodation {
    accommodations {
      pageContent {
        __typename
        ...SinglePageBannerFragment
        ...ImageWithContentFragment
        ...AccommodationSimpleContentFragment
        ...GalleryFragment
        ...ImageWithTabContentFragment
        ...RelatedAccommodationsFragment
        ...LandscapeMediaWithContentFragment
      }
    }
  }
`;
